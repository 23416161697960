import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import type { AppProps } from 'next/app';
import Modal from 'react-modal';
import { RecoilRoot } from 'recoil';
import { usePageView } from '../hooks/usePageView';
import createEmotionCache from '../lib/createEmotionCache';
import theme from '../lib/theme';
import '../modules/FirebaseApp';
import '../styles/globals.scss';
const clientSideEmotionCache = createEmotionCache();
interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache;
}
Modal.setAppElement('#__next');
const MyApp = ({
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
}: MyAppProps) => {
    usePageView();
    return (
        <RecoilRoot>
            <CacheProvider value={emotionCache}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Component {...pageProps} />
                </ThemeProvider>
            </CacheProvider>
        </RecoilRoot>
    );
};

export default MyApp;
