import { initializeApp } from 'firebase/app';
import {
    getToken,
    initializeAppCheck,
    ReCaptchaV3Provider,
} from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
const firebaseConfig = {
    apiKey: 'AIzaSyBB9faXeYXh8yiB5qXCG-sqf6Pc-VTy2zI',
    authDomain: 'codespecialactivitieshowmany.firebaseapp.com',
    databaseURL:
        'https://codespecialactivitieshowmany-default-rtdb.firebaseio.com',
    projectId: 'codespecialactivitieshowmany',
    storageBucket: 'codespecialactivitieshowmany.appspot.com',
    messagingSenderId: '1000520130530',
    appId: '1:1000520130530:web:2076d01b6604b3481fa3bb',
    measurementId: 'G-H5NLBXZ9SG',
};
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
declare global {
    var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}
if (typeof document !== 'undefined') {
    if (process.env.NODE_ENV === 'development') {
        window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    }
    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(
            process.env.NEXT_PUBLIC_RECAPTCHAV3_SITE_KEY || '',
        ),
        isTokenAutoRefreshEnabled: true,
    });
    getToken(appCheck)
        .then(() => {
            console.log('AppCheck:Success');
        })
        .catch((error) => {
            console.log(error.message);
        });
}
